import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { IdpProviderComponent } from './Hooks/useIdp';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './Routes/AppRoutes';
import { QueryClientProvider, QueryClient } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './variables';
import { GoogleProviderComponent } from './Hooks/useGoogle';
import { googleInterceptor } from './Interceptors/googleInterceptor';

const queryClient = new QueryClient();

googleInterceptor();

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleProviderComponent>
        <IdpProviderComponent>
          <QueryClientProvider client={queryClient}>
            <Router>
              <AppRoutes />
            </Router>
          </QueryClientProvider>
        </IdpProviderComponent>
      </GoogleProviderComponent>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
