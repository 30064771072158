import { Col, Row, Spin } from 'antd';
import React from 'react';
import { STRAPI_BASE_URL } from '../variables';

const Login = () => {
  window.location.assign(`${STRAPI_BASE_URL}/api/connect/google`);

  return (
    <Row justify="space-around" style={{ height: '350px' }} align="middle">
      <Col span={1}>
        <Spin size="large" />
      </Col>
    </Row>
  );
};

export default Login;
