import React, { useEffect, useState } from 'react';
import { patchCalendarEvent, useCalendarEvent } from '../../../Hooks/useCalendarEvent';
import { formatDate } from '../../../Utilities/functions';
import { EventAttendee } from '../../../Services/CalendarService';
import { Result, Skeleton } from 'antd';
import { useQueryClient } from 'react-query';
import './Event.less';
import AttendeeIcon from './AttendeeIcon/AttendeeIcon';
import { useBreadcrumbs } from '../../../Hooks/useBreadcrumbs';
import { useLocation } from 'react-router-dom';
import { useGoogle } from '../../../Hooks/useGoogle';
import GoogleLogin from '../../General/GoogleLogin/GoogleLogin';

interface Props {
  calendarId: string;
  eventId: string;
}

export default function Event({ calendarId, eventId }: Props) {
  const { data: response, status } = useCalendarEvent(calendarId, eventId);
  const [self, setSelf] = useState<EventAttendee>();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { authenticated, googleLogin } = useGoogle();

  useEffect(() => {
    setTimeout(() => {
      setBreadcrumbs([]);
    });
  }, []);

  useEffect(() => {
    if (response) {
      setSelf(response.data.attendees?.find((a) => a.self));

      setBreadcrumbs([
        {
          label: response?.data?.summary,
          url: pathname
        }
      ]);
    }
  }, [response]);

  function getSelfAttendance() {
    const self = response?.data?.attendees?.find((attendee) => attendee.self);

    if (self) {
      switch (self.responseStatus) {
        case 'accepted':
        case 'tentative':
          return 'U bent ingeschreven voor dit event';
        case 'declined':
        case 'needsAction':
          return 'U bent niet ingeschreven voor dit event';
        default:
          return 'Er gaat iets fout';
      }
    }
  }

  async function toggleAttendance() {
    const self = response?.data.attendees.find(
      (user) => user.email === JSON.parse(localStorage.getItem('auth')).email
    );

    if (self && response) {
      self.responseStatus = self.responseStatus === 'accepted' ? 'declined' : 'accepted';

      await patchCalendarEvent(calendarId, eventId, {
        ...response.data,
        start: {
          dateTime: response?.data.start.dateTime
        },
        end: {
          dateTime: response?.data.end.dateTime
        },
        attendees: [
          {
            ...self
          }
        ]
      });

      queryClient.invalidateQueries(['getCalendarEventById', calendarId, eventId]);
    }
  }

  switch (status) {
    case 'loading':
      return <Skeleton />;
    case 'success':
    case 'idle':
      return (
        <div className="event-component">
          <h3>{response?.data?.summary}</h3>
          <p>
            {formatDate(response?.data?.start)} - {formatDate(response?.data?.end)}
          </p>
          <p>{getSelfAttendance()}</p>
          {authenticated ? (
            <button onClick={toggleAttendance}>
              {self?.responseStatus === 'accepted' ? 'Uitschrijven' : 'Inschrijven'}
            </button>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '12px' }}>
                Om in/uit te schrijven dient u eerst in te loggen
              </span>
              <GoogleLogin onClick={() => googleLogin()} />
            </div>
          )}
          <ul>
            {response?.data?.attendees?.map((attendee) => {
              return (
                <li key={attendee?.email} className={attendee?.self ? 'self' : ''}>
                  {attendee?.displayName || attendee?.email}&nbsp;
                  <span className={attendee.responseStatus}>
                    <AttendeeIcon status={attendee.responseStatus} />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de pagina inhoud'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}
