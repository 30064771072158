import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IdpStatus, useIdp } from '../Hooks/useIdp';
import { api } from '../Services/Api';

function Callback() {
  const { getToken, status, auth } = useIdp();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location) {
      return;
    }
    const { search } = location;
    getToken(search);
  }, []);

  useEffect(() => {
    if (status === IdpStatus.COMPLETE && auth) {
      api.setToken(auth.accessToken);
      history.replace('/home');
    }
  }, [status, auth, history]);

  return <div>{status}</div>;
}

export default Callback;
