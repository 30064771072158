import React from 'react';
import { Content } from '../../../../../Models/Content';
import { Image } from 'antd';
import { getImagePath } from '../../../../../Utilities/functions';

interface Props {
  contentItem: Content;
}

export default function ImageBlock({ contentItem }: Props) {
  const imageUrl = getImagePath(contentItem.Image?.data.attributes.url || '');
  return <Image src={imageUrl} />;
}
