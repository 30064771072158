import ApiService from './ApiService';

export interface NavItem {
  id: string;
  title: string;
  menuAttached: boolean;
  path: string;
  type: string;
  uiRouterKey: string;
  slug: string;
  external: boolean;
  related: Related;
  items: NavItem[];
}

export interface Related {
  __contentType: string;
  id: number;
  title: string;
  slug: string;
  locale: string;
  published_at: Date;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
  __templateName: string;
}

class NavigationItemService extends ApiService {
  call = async (id: number): Promise<NavItem[]> => {
    return this.api.get<NavItem[]>(`/navigation/render/${id}?type=TREE`);
  };
}

export const navigationItemService = new NavigationItemService();
