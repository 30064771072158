import { useQuery, UseQueryResult } from 'react-query';
import { StrapiPageResponse, strapiPageService, Tag } from '../Services/StrapiPageService';

const getPageBySlug = async ({ queryKey }: { queryKey: any }): Promise<StrapiPageResponse> => {
  const [_key, slug] = queryKey;
  return await strapiPageService.call(slug);
};

const getPageByTag = async ({ queryKey }: { queryKey: any }): Promise<StrapiPageResponse> => {
  const [_key, tag, limit, start] = queryKey;
  return await strapiPageService.byTag(tag, limit, start);
};

export function useStrapiPage(slug: string): UseQueryResult<StrapiPageResponse, Error> {
  return useQuery(['getPageBySlug', slug], getPageBySlug, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10
  });
}

export function useStrapiPageByTag(
  tag: Tag | undefined,
  limit = 25,
  start = 0
): UseQueryResult<StrapiPageResponse, Error> {
  return useQuery(['getPagesByTag', [tag?.attributes?.name], limit, start], getPageByTag, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10,
    keepPreviousData: true
  });
}

export function useStrapiPageByTagNames(
  tags: string[],
  limit = -1
): UseQueryResult<StrapiPageResponse, Error> {
  return useQuery(['getPagesByTag', tags, limit], getPageByTag, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10,
    keepPreviousData: true
  });
}
