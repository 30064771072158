import React from 'react';
import { Content } from '../../../../../Models/Content';
import ReactMarkdown from 'react-markdown';
import rehypeMermaid from '../../../../Mermaid/RemarkMermaid';
import SanitizeHTML from '../../../../General/SanitizeHTML/SanitizeHTML';

import './TextBlock.less';

export interface Props {
  contentItem: Content;
}

export default function TextBlock({ contentItem }: Props) {
  const { RichText, __component, id } = contentItem;

  return <SanitizeHTML html={RichText ?? ''} />;
}
