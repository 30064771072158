// @ts-nocheck
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { STRAPI_BASE_URL } from '../variables';

export interface ParameterMessage {
  code: string;
  reden: string;
  naam: string;
}

export interface ErrorMessage {
  tijdstip: string;
  titel: string;
  status: number;
  code: string;
}

export class ServiceError extends Error {
  statusCode?: number;
  data?: ErrorMessage;

  constructor(message?: string, statusCode?: number, data?: ErrorMessage) {
    super(message);
    this.statusCode = statusCode;
    this.data = data;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class Api {
  http: AxiosInstance;
  token = '';
  refreshFunc = undefined as any;

  constructor() {
    this.http = axios.create({
      baseURL: `${STRAPI_BASE_URL}/api`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  handleResponse = (res: AxiosResponse, refreshRequest = false) => {
    if (res.status !== StatusCodes.OK && res.statusText !== getReasonPhrase(StatusCodes.OK)) {
      if (res.status === StatusCodes.FORBIDDEN || res.status === StatusCodes.UNAUTHORIZED) {
        if (!refreshRequest && res.data?.code === 'verlopen') {
          return this.refreshFunc().then(() => {
            return this.request(res.config, true);
          });
        } else {
          throw new ServiceError('U bent niet geautoriseerd', res.status, res.data);
        }
      } else {
        throw new ServiceError(`Request rejected with status ${res.status}`, res.status, res.data);
      }
    }
    return res.data;
  };

  setRefreshFunc = (refreshFunc: () => void) => {
    this.refreshFunc = refreshFunc;
  };

  setToken = (token: string) => {
    this.token = token;

    this.http.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers.Authorization = `Bearer ${this.token}`;
      return config;
    });
  };

  request = async <T>(config: AxiosRequestConfig, refreshRequest: boolean): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.request(config);
      return res.data;
    } catch ({ response: res }) {
      return this.handleResponse(res, refreshRequest);
    }
  };

  get = async <T>(path: string, headers?: any, params?: any): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.get(path, { headers, params });
      return res.data;
    } catch ({ response: res }) {
      return this.handleResponse(res);
    }
  };

  post = async <T>(path: string, data: any): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.post(path, data);
      return res.data;
    } catch ({ response: res }) {
      return this.handleResponse(res);
    }
  };

  put = async <T>(path: string, data: any): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.put(path, data);
      return res.data;
    } catch ({ response: res }) {
      return this.handleResponse(res);
    }
  };

  patch = async <T>(path: string, data: any): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.patch(path, data);
      return res.data;
    } catch ({ response: res }) {
      return this.handleResponse(res);
    }
  };
}

export const api = new Api();
