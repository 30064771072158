import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface Props {
  status: 'needsAction' | 'accepted' | 'declined' | 'tentative';
}

export default function AttendeeIcon({ status }: Props) {
  switch (status) {
    case 'needsAction':
      return <FontAwesomeIcon icon={faQuestion} />;
    case 'accepted':
      return <FontAwesomeIcon icon={faCheck} />;
    case 'tentative':
      return (
        <>
          <FontAwesomeIcon icon={faCheck} />
          <FontAwesomeIcon icon={faQuestion} />
        </>
      );
    case 'declined':
      return <FontAwesomeIcon icon={faXmark} />;
    default:
      return <FontAwesomeIcon icon={faQuestion} />;
  }
}
