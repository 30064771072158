import { useQuery, UseQueryResult } from 'react-query';
import {
  StrapiSingletonResponse,
  strapiSingletonService
} from '../Services/StrapiSingletonService';

const getSingletonById = async ({
  queryKey
}: {
  queryKey: any;
}): Promise<StrapiSingletonResponse> => {
  const [_key, id] = queryKey;
  return await strapiSingletonService.call(id);
};

export function useStrapiSingleton(id: string): UseQueryResult<StrapiSingletonResponse, Error> {
  return useQuery(['getSingletonById', id], getSingletonById, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10
  });
}
