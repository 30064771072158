import React from 'react';
import { Breadcrumb } from '../Models/Breadcrumb';

interface ContextState {
  breadcrumbs?: Breadcrumb[];
  setBreadcrumbs: any;
}

const BreadcrumbsContext = React.createContext<ContextState | undefined>(undefined);

export function useBreadcrumbs() {
  const context = React.useContext(BreadcrumbsContext);
  if (context === undefined) {
    throw new Error('`useBreadcrumbs` must be used with an `BreadcrumbsProvider`');
  }
  return context;
}

export const BreadcrumbsProviderComponent = (props: React.PropsWithChildren<unknown>) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState();

  const context = {
    breadcrumbs,
    setBreadcrumbs
  };

  return (
    <BreadcrumbsContext.Provider value={context}>{props.children}</BreadcrumbsContext.Provider>
  );
};
