import React, { useRef } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { decodeToken } from 'jsontokens';
import axios from 'axios';
import { STRAPI_BASE_URL } from '../variables';
import { useGoogle } from './useGoogle';

export enum IdpStatus {
  DEFAULT,
  INIT,
  AUTHORIZE_CODE,
  GET_TOKEN,
  REFRESH_TOKEN,
  COMPLETE,
  ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED
}

interface StrapiToken {
  jwt: string;
  user: User;
}

interface User {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: null;
  role: Role;
  createdAt: string;
  updatedAt: string;
}

interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
}

interface TokenPayload {
  [key: string]: any;

  iss?: string;
  jti?: string;
  iat: number;
  exp: number;
}

interface TokenBaseResponse {
  accessToken: string;
  expiresIn: number;
  issuedAt: number;
  email: string;
  username: string;
  createdAt: string;
  role: Role;
}

const initialAuth = {
  accessToken: '',
  expiresIn: 0,
  issuedAt: 0
} as TokenBaseResponse;

interface ContextState {
  baseUrl?: string;
  status: IdpStatus;
  login: () => void;
  logout: () => void;
  isLoggedIn: () => boolean;
  getToken: (token: string) => Promise<void>;
  auth: TokenBaseResponse;
}

const IdpContext = React.createContext<ContextState | undefined>(undefined);

export function useIdp() {
  const context = React.useContext(IdpContext);
  if (context === undefined) {
    throw new Error('`useIdp` must be used with an `IdpProvider`');
  }
  return context;
}

export const IdpProviderComponent = (props: React.PropsWithChildren<unknown>) => {
  const [status, setStatus] = React.useState<IdpStatus>(IdpStatus.INIT);
  const [auth, setAuth] = useLocalStorage<TokenBaseResponse>('auth', initialAuth);
  const hostname = useRef<string>();
  const { setAccessToken, setExpiresIn } = useGoogle();

  const login = async (): Promise<void> => {
    window.location.assign(`${STRAPI_BASE_URL}/api/connect/google`);
  };

  const getToken = async (token: string): Promise<void> => {
    const googleAccessToken = token.substring(
      token.indexOf('&access_token=') + 14,
      token.lastIndexOf('&raw%5Baccess_token%5D=')
    );

    setAccessToken(googleAccessToken);
    setExpiresIn(Date.now() + 3600000);

    axios
      .get<StrapiToken>(`${STRAPI_BASE_URL}/api/auth/google/callback${token}`)
      .then((res) => {
        return res.data;
      })
      .then((strapiToken) => {
        const tokenData = decodeToken(strapiToken.jwt).payload as TokenPayload;
        setAuth({
          accessToken: strapiToken.jwt,
          expiresIn: tokenData.exp,
          issuedAt: tokenData.iat,
          email: strapiToken.user.email,
          username: strapiToken.user.username,
          createdAt: strapiToken.user.createdAt,
          role: strapiToken.user.role
        });
        setStatus(IdpStatus.COMPLETE);
      });
  };

  const logout = async (): Promise<void> => {
    setAuth(initialAuth);
    setStatus(IdpStatus.LOGOUT_SUCCESS);
  };

  const isLoggedIn = (): boolean => {
    if (auth?.accessToken) {
      return auth.expiresIn > Date.now() / 1000;
    }
    return false;
  };

  const context = {
    setBaseUrl: (uri: string): void => {
      hostname.current = uri;
    },
    login,
    status,
    auth,
    logout,
    isLoggedIn,
    getToken
  };

  return <IdpContext.Provider value={context}>{props.children}</IdpContext.Provider>;
};
