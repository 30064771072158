import { Col, Result, Row, Skeleton, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStrapiPage } from '../../../Hooks/useStrapiPage';
import { GridSizes } from '../../../Models/GridSizes';
import { useFooter } from '../../../Hooks/useFooter';
import ContentBlocks from '../ContentBlocks/ContentBlocks';
import { getImagePath } from '../../../Utilities/functions';

const { Title } = Typography;

interface ParamTypes {
  slug: string;
}

export default function StrapiPage() {
  const { slug } = useParams<ParamTypes>();
  const { data: pageResponse, status } = useStrapiPage(slug);
  const { setFooterVisibility } = useFooter();

  useEffect(() => {
    if (pageResponse?.data[0]) {
      setFooterVisibility(pageResponse?.data[0].attributes.show_footer);
    }
  }, [pageResponse]);

  switch (status) {
    case 'loading':
      return <Skeleton />;
    case 'success':
    case 'idle':
      if (pageResponse && pageResponse.data.length === 1) {
        const page = pageResponse.data[0].attributes;
        return (
          <div className="site-layout-background">
            {page.banner?.data?.attributes ? (
              <Row
                align="middle"
                style={{
                  height: page.banner?.data?.attributes?.height,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${getImagePath(page.banner?.data?.attributes?.url || '')})`
                }}
              >
                <Col offset={3}>
                  <Title style={{ color: 'white' }}>{page.title}</Title>
                </Col>
              </Row>
            ) : (
              <Row className={'strapi-page-title-block'}>
                <Col>
                  <Title>{page.title}</Title>
                </Col>
              </Row>
            )}
            <Row gutter={32}>
              {page.Content.map((contentItem) => {
                const sizes = {
                  ...(contentItem['size_desktop'] && {
                    xl: GridSizes[contentItem['size_desktop']]
                  }),
                  ...(contentItem['size_tablet'] && { md: GridSizes[contentItem['size_tablet']] }),
                  xs: 24
                };

                return (
                  <Col {...sizes} key={contentItem.id}>
                    <ContentBlocks contentItem={contentItem} />
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      }
      return <Result title={'De opgevraagde pagina heeft geen inhoud.'} status={'info'} />;
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de pagina inhoud'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}
