import React from 'react';
import { useParams } from 'react-router-dom';
import Event from './Event/Event';

interface ParamTypes {
  calendarId: string;
  id: string;
}

export default function CalendarEvent() {
  const { calendarId, id } = useParams<ParamTypes>();

  return <Event calendarId={calendarId} eventId={id} />;
}
