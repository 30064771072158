import logo from '../../../Images/btn_google_dark_normal_ios.svg';
import React from 'react';
import './GoogleLogin.less';

interface Props {
  onClick: () => void;
}

export default function GoogleLogin({ onClick }: Props) {
  return (
    <div className="google-btn" onClick={() => onClick()}>
      <div className="google-icon-wrapper">
        <img src={logo} alt="google_logo" />
      </div>
      <p className="btn-text">
        <b>Sign in with google</b>
      </p>
    </div>
  );
}
