import { Col, Result, Row, Pagination } from 'antd';
import React, { useState } from 'react';
import { useStrapiPageByTag } from '../../../../../Hooks/useStrapiPage';
import { Link } from 'react-router-dom';
import { Content } from '../../../../../Models/Content';
import './ListPagesByTagBlock.less';
import { getImagePath } from '../../../../../Utilities/functions';

interface Props {
  contentItem: Content;
}

export default function ListPagesByTagBlock({ contentItem }: Props) {
  const [currentPage, setCurrentPage] = useState(0);
  const { Limit, Pagination: PaginationEnabled } = contentItem;
  const tag = contentItem.tags?.data;
  const paginationEnabled = contentItem.Pagination;
  const placeholder = contentItem.image_placeholder;
  const columns = {
    ...(contentItem['Columns_desktop'] && { xl: 24 / Number(contentItem['Columns_desktop']) }),
    ...(contentItem['Columns_tablet'] && { md: 24 / Number(contentItem['Columns_tablet']) }),
    ...(contentItem['Columns_mobile'] && { xs: 24 / Number(contentItem['Columns_mobile']) })
  };

  const { data: pageResponse, status } = useStrapiPageByTag(
    tag,
    Limit,
    Limit && currentPage * Limit
  );

  function onChange(page: number) {
    if (PaginationEnabled && pageResponse) {
      setCurrentPage(page - 1);
    }
  }

  switch (status) {
    case 'loading':
    case 'success':
    case 'idle':
      return (
        <div className="card_items">
          {contentItem.Title ? (
            <div className="card_items-title">
              <span>{contentItem.Title}</span>
            </div>
          ) : (
            ''
          )}
          <Row gutter={32}>
            {pageResponse?.data.map((item) => {
              const banner_card = item?.attributes?.banner_card?.data?.attributes?.url;
              const tag_image = tag?.attributes?.image?.data?.attributes?.url;
              const block_placeholder = placeholder?.data?.attributes?.url;

              let image = block_placeholder;
              if (banner_card) {
                image = banner_card;
              } else if (tag_image) {
                image = tag_image;
              }

              return (
                <Col {...columns} key={item.attributes.slug}>
                  <Link
                    to={`${contentItem.tags?.data.attributes.name
                      .toLowerCase()
                      .replaceAll(' ', '-')}/${item.attributes.slug}`}
                  >
                    <div
                      className="item"
                      style={{ backgroundImage: `url(${getImagePath(image || '')})` }}
                    >
                      <span className="title">
                        <p>{item.attributes.title}</p>
                      </span>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
          {paginationEnabled && pageResponse && (
            <Pagination
              onChange={onChange}
              current={currentPage + 1}
              pageSize={pageResponse?.meta.pagination.limit}
              total={pageResponse?.meta.pagination.total}
            />
          )}
        </div>
      );
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de pagina inhoud'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}
