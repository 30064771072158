import React from 'react';
import { FooterVisibility } from '../Services/StrapiPageService';

interface ContextState {
  footerVisibility?: FooterVisibility;
  setFooterVisibility: any;
}

const FooterContext = React.createContext<ContextState | undefined>(undefined);

export function useFooter() {
  const context = React.useContext(FooterContext);
  if (context === undefined) {
    throw new Error('`useFooter` must be used with an `FooterProvider`');
  }
  return context;
}

export const FooterProviderComponent = (props: React.PropsWithChildren<unknown>) => {
  const [footerVisibility, setFooterVisibility] = React.useState();

  const context = {
    footerVisibility,
    setFooterVisibility
  };

  return <FooterContext.Provider value={context}>{props.children}</FooterContext.Provider>;
};
