import React from 'react';
import { Content } from '../../../../../Models/Content';

interface Props {
  contentItem: Content;
}

export default function UnknownBlock({ contentItem }: Props) {
  return (
    <div key={`${contentItem.__component}-${contentItem.id}`}>
      Unknown __component: {contentItem.__component}
    </div>
  );
}
