import {
  CalendarEventInterface,
  CalendarEventResponse,
  calendarService,
  EventAttendee
} from '../Services/CalendarService';
import { useQuery, UseQueryResult } from 'react-query';

const getEventById = async ({ queryKey }: { queryKey: any }): Promise<any> => {
  const [_key, calendarId, eventId] = queryKey;
  return await calendarService.getEventById(calendarId, eventId).then((response) => {
    response.data.attendees?.sort((a: EventAttendee, b: EventAttendee): any => {
      if (a.responseStatus === 'accepted') {
        return -1;
      }

      if (a.responseStatus === 'needsAction' && b.responseStatus === 'declined') {
        return -1;
      }
    });

    return response;
  });
};

export function useCalendarEvent(
  calendarId: string,
  eventId: string
): UseQueryResult<CalendarEventResponse, Error> {
  return useQuery(['getCalendarEventById', calendarId, eventId], getEventById, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10
  });
}

export function patchCalendarEvent(
  calendarId: string,
  eventId: string,
  body: CalendarEventInterface
) {
  return calendarService.patchEventById(calendarId, eventId, body);
}
