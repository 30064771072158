import MenuTopic from '../../../../Home/MenuTopic/MenuTopic';
import React, { useEffect, useState } from 'react';
import { Content } from '../../../../../Models/Content';
import './CalendarBlock.less';
import { useGoogle } from '../../../../../Hooks/useGoogle';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../../Utilities/functions';
import { calendarService } from '../../../../../Services/CalendarService';

interface Props {
  contentItem: Content;
}

interface CalendarEvent {
  id: string;
  summary: string;
  htmlLink: string;
  start: {
    date?: string;
    dateTime?: string;
  };
}

export default function CalendarBlock({ contentItem }: Props) {
  const [items, setItems] = useState<CalendarEvent[]>([]);
  const [userEmail, setUserEmail] = useState();

  const { authenticated } = useGoogle();

  useEffect(() => {
    // @ts-ignore
    const email = JSON.parse(localStorage.getItem('auth')).email;

    if (email) {
      setUserEmail(email);
    }

    calendarService.getEvents(contentItem.CalendarId)?.then((response) => {
      setItems(
        response.data.items.filter((item: any) =>
          item.attendees.some((attendee: any) => attendee.email === email)
        )
      );
    });
  }, [authenticated]);

  return (
    <div className="menu-list">
      <MenuTopic title="Upcoming events" path="/">
        {items.slice(0, contentItem.Limit ?? -1)?.map((event: any) => {
          return (
            <div className="menu__item" key={event.id}>
              <Link to={`./${userEmail}/event/${event.id}`}>
                <span className="menu__item-label menu__item-date">
                  <span>{formatDate(event.start)}</span>
                  <br /> {event.summary}
                </span>
              </Link>
            </div>
          );
        })}
      </MenuTopic>
    </div>
  );
}
