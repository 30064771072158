import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import './Home.less';
import { useStrapiSingleton } from '../../Hooks/useStrapiSingleton';
import { useFooter } from '../../Hooks/useFooter';
import { GridSizes } from '../../Models/GridSizes';
import ContentBlocks from '../Layout/ContentBlocks/ContentBlocks';

function Home() {
  const { data: pageResponse } = useStrapiSingleton('Home');
  const { setFooterVisibility } = useFooter();

  useEffect(() => {
    setFooterVisibility(pageResponse?.data.attributes.show_footer);
  }, [pageResponse]);

  return (
    <Row gutter={32}>
      {pageResponse?.data.attributes.Content.map((contentItem) => {
        const sizes = {
          ...(contentItem['size_desktop'] && { xl: GridSizes[contentItem['size_desktop']] }),
          ...(contentItem['size_tablet'] && { md: GridSizes[contentItem['size_tablet']] }),
          xs: 24
        };

        return (
          <Col {...sizes} key={`${contentItem.__component}-${contentItem.id}`}>
            <div className="home-block">
              <ContentBlocks contentItem={contentItem} />
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

export default Home;
