import axios from 'axios';
import { STRAPI_BASE_URL } from '../variables';
// @ts-ignore
import dateFormat from 'dateformat';

export interface CalendarEventResponse {
  data: CalendarEventInterface;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
      limit?: number;
    };
  };
}

export interface CalendarEventInterface {
  created: string;
  id: string;
  status: string;
  summary: string;
  attendees?: EventAttendee[];
  start: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
  end: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
}

export interface EventAttendee {
  displayName: string;
  email: string;
  responseStatus: AttendeeResponseStatus;
  self?: boolean;
}

type AttendeeResponseStatus = 'needsAction' | 'accepted' | 'declined' | 'tentative';

class CalendarService {
  getEventById(calendarId: string, eventId: string) {
    return axios.get(`${STRAPI_BASE_URL}/calendar-events/event/${calendarId}/${eventId}`, {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.auth).accessToken}` }
    });
  }

  getEvents(calendarId?: string) {
    if (!calendarId) {
      return null;
    }
    return axios.get(
      `${STRAPI_BASE_URL}/calendar-events/events/${calendarId}?timeMin=${encodeURIComponent(
        dateFormat(Date.now(), 'isoDateTime')
      )}&singleEvents=true&orderBy=startTime`,
      {
        headers: { Authorization: `Bearer ${JSON.parse(localStorage.auth).accessToken}` }
      }
    );
  }

  patchEventById(calendarId: string, eventId: string, body: CalendarEventInterface) {
    return axios.patch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
      body,
      {
        headers: { Authorization: `Bearer ${JSON.parse(localStorage.google_auth).accessToken}` }
      }
    );
  }
}

export const calendarService = new CalendarService();
