export type GridSize =
  | 'Breedte 100%'
  | 'Breedte 75%'
  | 'Breedte 66%'
  | 'Breedte 50%'
  | 'Breedte 33%'
  | 'Breedte 25%';

export const GridSizes = {
  'Breedte 100%': 24,
  'Breedte 75%': 18,
  'Breedte 66%': 16,
  'Breedte 50%': 12,
  'Breedte 33%': 8,
  'Breedte 25%': 6
};
