import { api, Api } from './Api';

class ApiService {
  api: Api;

  constructor() {
    this.api = api;
  }
}

export default ApiService;
