import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { useIdp } from '../Hooks/useIdp';
import Callback from '../Pages/Callback';
import { api } from '../Services/Api';
import { FooterProviderComponent } from '../Hooks/useFooter';
import Login from '../Pages/Login';
import PrivateRoutes from './PrivateRoutes';
import { BreadcrumbsProviderComponent } from '../Hooks/useBreadcrumbs';

const AppRoutes = withRouter(() => {
  const { auth } = useIdp();

  api.setToken(auth.accessToken);

  return (
    <FooterProviderComponent>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/callback" component={Callback} />
        <BreadcrumbsProviderComponent>
          <Route path="/" component={PrivateRoutes} />
        </BreadcrumbsProviderComponent>
      </Switch>
    </FooterProviderComponent>
  );
});

export default AppRoutes;
