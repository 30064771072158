import { Content } from '../../../../../Models/Content';
import { useNavigationItems } from '../../../../../Hooks/useNavigationItems';
import { Result } from 'antd';
import React from 'react';
import MenuListElement from '../MenuListElement/MenuListElement';

interface Props {
  contentItem: Content;
}

export default function NewUserMenuListBlock({ contentItem }: Props) {
  const created = new Date(JSON.parse(localStorage.getItem('auth')!).createdAt);
  const date = new Date();

  date.setMonth(date.getMonth() - 1);

  const { data, status } =
    created.getTime() > date.getTime()
      ? useNavigationItems(contentItem.NewUserMenuId ?? -1)
      : useNavigationItems(contentItem.MenuId ?? -1);

  switch (status) {
    case 'loading':
    case 'success':
    case 'idle':
      return <MenuListElement data={data} />;
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de pagina inhoud'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}
