import ApiService from './ApiService';
import { Content } from '../Models/Content';

export interface StrapiSingletonResponse {
  data: StrapiSingletonAttributes;
  meta: any;
}

export interface StrapiSingletonAttributes {
  attributes: StrapiSingleton;
}

export interface StrapiSingleton {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  Content: Content[];

  [key: string]: any;
}

class StrapiSingletonService extends ApiService {
  call = async (id: string): Promise<StrapiSingletonResponse> => {
    return this.api.get<StrapiSingletonResponse>(`/${id}?&populate=deep`);
  };
}

export const strapiSingletonService = new StrapiSingletonService();
