import { GridSize } from './GridSizes';
import { ImageData } from './Image';
import { Columns, StrapiPage, Tag } from '../Services/StrapiPageService';

export enum ContentBlock {
  TEXT = 'content.text',
  IMAGE = 'content.image',
  LIST_PAGES_BY_TAG = 'content.list-pages-by-tag',
  MENU_LIST = 'content.menu-list',
  NEW_USER_MENU_LIST = 'content.new-user-menu-list',
  LINKS = 'content.links',
  CALENDAR = 'content.calendar'
}

export interface Content {
  __component: string;
  id: number;
  RichText?: string;
  size_desktop: GridSize;
  size_tablet?: GridSize;
  size_mobile?: GridSize;
  Columns_desktop?: Columns;
  Columns_tablet?: Columns;
  Columns_mobile?: Columns;
  Image?: ImageData;
  image_placeholder?: ImageData;
  Limit?: number;
  Title?: string;
  Pagination?: boolean;
  MenuId?: number;
  NewUserMenuId?: number;
  CalendarId?: string;
  Link?: Link[];
  tags?: {
    data: Tag;
  };
}

export interface Link {
  id: number;
  Title: string;
  Size: GridSize;
  page: {
    data: {
      id: number;
      attributes: StrapiPage;
    };
  };
}
