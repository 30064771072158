import TextBlock from './Blocks/TextBlock/TextBlock';
import ImageBlock from './Blocks/ImageBlock/ImageBlock';
import ListPagesByTagBlock from './Blocks/ListPagesByTagBlock/ListPagesByTagBlock';
import UnknownBlock from './Blocks/UnknownBlock/UnknownBlock';
import React from 'react';
import { Content, ContentBlock } from '../../../Models/Content';
import MenuListBlock from './Blocks/MenuListBlock/MenuListBlock';
import LinkBlock from './Blocks/LinkBlock/LinkBlock';
import CalendarBlock from './Blocks/CalendarBlock/CalendarBlock';
import NewUserMenuListBlock from './Blocks/NewUserMenuListBlock/NewUserMenuListBlock';

interface Props {
  contentItem: Content;
}

export default function ContentBlocks({ contentItem }: Props) {
  switch (contentItem.__component) {
    case ContentBlock.TEXT:
      return <TextBlock contentItem={contentItem} />;
    case ContentBlock.IMAGE:
      return <ImageBlock contentItem={contentItem} />;
    case ContentBlock.LIST_PAGES_BY_TAG:
      return <ListPagesByTagBlock contentItem={contentItem} />;
    case ContentBlock.MENU_LIST:
      return <MenuListBlock contentItem={contentItem} />;
    case ContentBlock.NEW_USER_MENU_LIST:
      return <NewUserMenuListBlock contentItem={contentItem} />;
    case ContentBlock.LINKS:
      return <LinkBlock contentItem={contentItem} />;
    case ContentBlock.CALENDAR:
      return <CalendarBlock contentItem={contentItem} />;
    default:
      return <UnknownBlock contentItem={contentItem} />;
  }
}
