import { useQuery, UseQueryResult } from 'react-query';
import { navigationItemService, NavItem } from '../Services/NavigationService';

export function useNavigationItems(id: number): UseQueryResult<NavItem[], Error> {
  const getNavItems = async (): Promise<NavItem[]> => {
    return navigationItemService.call(id);
  };

  return useQuery([`navItems${id}`], getNavItems, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 10
  });
}
