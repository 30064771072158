import { Content } from '../../../../../Models/Content';
import { Link } from 'react-router-dom';
import React from 'react';

interface Props {
  contentItem: Content;
}

export default function LinkBlock({ contentItem }: Props) {
  return (
    <>
      <h3>{contentItem.Title}</h3>
      {contentItem.Link &&
        contentItem?.Link.map((link) => {
          const { page } = link;
          if (!page?.data) return;

          return (
            <div key={link.id}>
              <Link to={page.data.attributes.slug}>{link.Title}</Link>
            </div>
          );
        })}
    </>
  );
}
