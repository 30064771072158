import './Footer.less';
import { useStrapiSingleton } from '../../Hooks/useStrapiSingleton';
import { Col, Result, Row } from 'antd';
import { GridSizes } from '../../Models/GridSizes';
import React from 'react';
import { useFooter } from '../../Hooks/useFooter';
import ContentBlocks from './ContentBlocks/ContentBlocks';

function Footer() {
  const { footerVisibility } = useFooter();

  const { data, status } = useStrapiSingleton('Footer');

  switch (status) {
    case 'loading':
    case 'success':
    case 'idle':
      if (
        (data?.data.attributes.Footer && footerVisibility !== 'Always hide') ||
        footerVisibility === 'Always show'
      ) {
        return (
          <footer className="fade-in">
            <div className="footer-content">
              <Row gutter={32}>
                {data?.data.attributes.Content.map((contentItem) => {
                  const sizes = {
                    ...(contentItem['size_desktop'] && {
                      xl: GridSizes[contentItem['size_desktop']]
                    }),
                    ...(contentItem['size_tablet'] && {
                      md: GridSizes[contentItem['size_tablet']]
                    }),
                    ...(contentItem['size_mobile'] && {
                      xs: GridSizes[contentItem['size_mobile']]
                    })
                  };

                  return (
                    <Col {...sizes} key={`${contentItem.__component}-${contentItem.id}`}>
                      <ContentBlocks contentItem={contentItem} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </footer>
        );
      } else {
        return <></>;
      }
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de footer'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}

export default Footer;
