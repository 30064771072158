import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useNavigationItems } from '../../Hooks/useNavigationItems';
import './Nav.less';
import logo from '../../Images/logo_titel.png';
import { NavItem } from '../../Services/NavigationService';

type SubNavProps = {
  items: Array<NavItem>;
  renderLinkComponent: (subItem: NavItem, isSubmenu: boolean) => JSX.Element;
};

export const SubNav = ({ items, renderLinkComponent }: SubNavProps) => {
  return (
    <div className="submenu">
      <div className="inner">{items.map((subItem) => renderLinkComponent(subItem, true))}</div>
    </div>
  );
};

export const Nav = () => {
  const { data } = useNavigationItems(1);
  const { pathname } = useLocation();
  const [opened, setOpened] = useState(false);

  const toggleMenu = () => {
    setOpened((prev) => !prev);
  };

  useEffect(() => {
    if (window && window.innerWidth < 768) {
      document.querySelector('body')!.classList[opened ? 'add' : 'remove']('no-scroll');
    }
  }, [opened]);

  function renderLinkComponent(item: NavItem, isSubmenu?: boolean): JSX.Element {
    const Element = isSubmenu ? 'div' : 'li';
    const internalUrl = item?.related?.slug ? `/${item?.related?.slug}` : '/';
    const url = item?.external ? item?.path : internalUrl;
    const textChild = <span>{item.title}</span>;

    return (
      <Element key={item.id}>
        {item?.external ? (
          <a href={url} rel="noreferrer" target="_blank">
            <div className="item">{textChild}</div>
          </a>
        ) : (
          <Link to={url} onClick={toggleMenu}>
            <div className={'item ' + (item.path === pathname ? 'active' : '')}>{textChild}</div>
          </Link>
        )}

        {item?.items?.length > 0 && (
          <SubNav items={item.items} renderLinkComponent={renderLinkComponent} />
        )}
      </Element>
    );
  }

  return (
    <nav id="primary-nav">
      <Row>
        <Col lg={{ span: 4 }} md={{ span: 6 }}>
          <Link to={'/'}>
            <img
              style={{
                display: 'inline-block',
                height: '64px',
                padding: '8px'
              }}
              src={logo}
              alt={'HybrIT'}
            />
          </Link>
        </Col>
        <Col md={{ span: 0 }} sm={{ span: 4 }}>
          <div className={'menu-toggle ' + (opened ? 'active' : '')} onClick={toggleMenu}>
            <div className="inner">
              {Array.from(Array(3)).map((_, i) => (
                <span key={i}></span>
              ))}
            </div>
          </div>
        </Col>
        <Col md={{ span: 18 }} sm={{ span: 20 }} xs={{ span: 24 }}>
          <ul className={opened ? 'opened' : ''}>
            {data?.map((item: NavItem) => renderLinkComponent(item))}
          </ul>
        </Col>
      </Row>
    </nav>
  );
};
