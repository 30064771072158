import axios from 'axios';
import { refreshTokens } from '../Utilities/functions';

export function googleInterceptor() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry &&
        !originalRequest.url.includes('/auth/google/refresh') &&
        JSON.parse(localStorage.getItem('google_auth')!).refreshToken
      ) {
        originalRequest._retry = true;
        const tokens = await refreshTokens();

        if (tokens) {
          localStorage.setItem(
            'google_auth',
            JSON.stringify({
              accessToken: tokens.data.access_token,
              refreshToken: tokens.data.refresh_token,
              expiresIn: tokens.data.expiry_date
            })
          );

          originalRequest.headers['Authorization'] = `Bearer ${tokens.data.access_token}`;
        }

        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}
