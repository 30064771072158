import { Breadcrumb, Layout, Row } from 'antd';
import React, { Fragment, useEffect } from 'react';
import '../../App.less';
import Footer from './Footer';
import { Nav } from './Nav';
import { useBreadcrumbs } from '../../Hooks/useBreadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb as BreadcrumbInterface } from '../../Models/Breadcrumb';

const { Header, Content } = Layout;

interface Props {
  children: React.ReactChild;
}

export default function Page({ children }: Props): React.ReactElement {
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { pathname } = useLocation();

  useEffect(() => {
    const breadcrumbsArr = urlToBreadcrumbs();

    setBreadcrumbs(breadcrumbsArr);
  }, [pathname]);

  const urlToBreadcrumbs = (): BreadcrumbInterface[] => {
    const urlParts = pathname.split('/').filter((item) => item && item !== 'home');

    let breadcrumbUrl = '';
    return urlParts.map((part) => {
      breadcrumbUrl += '/' + part;

      return {
        label: urlToLabel(part),
        url: breadcrumbUrl
      };
    });
  };

  const urlToLabel = (str: string) => {
    const strArray = str.replaceAll('-', ' ').split(' ');
    strArray[0] = strArray[0].charAt(0).toUpperCase() + strArray[0].substr(1);

    return strArray.join(' ');
  };

  return (
    <Layout className="layout">
      <Header className="header">
        <Nav />
      </Header>
      <Content role="main">
        <Breadcrumb>
          <Fragment key="home">
            <Breadcrumb.Item key="home">
              <Link to="/" aria-label="home">
                Home
              </Link>
            </Breadcrumb.Item>
            {breadcrumbs?.map((breadcrumb, i) => {
              return (
                <Breadcrumb.Item key={breadcrumb.label + i}>
                  <Link to={`${breadcrumb.url}`} aria-label={breadcrumb.url}>
                    <span>{breadcrumb.label}</span>
                  </Link>
                </Breadcrumb.Item>
              );
            })}
          </Fragment>
        </Breadcrumb>
        <>{children}</>
      </Content>
      <Footer />
    </Layout>
  );
}
