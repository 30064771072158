// @ts-ignore
import dateFormat from 'dateformat';
import axios from 'axios';
import { STRAPI_BASE_URL } from '../variables';

export function formatDate(dateObj?: { date?: string; dateTime?: string }) {
  if (!dateObj) {
    return;
  }

  const { date, dateTime } = dateObj;

  if (!date && !dateTime) {
    return;
  }

  if (date) {
    return dateFormat(new Date(date), 'dd-mm-yyyy');
  }
  if (dateTime) {
    return dateFormat(new Date(dateTime), 'dd-mm-yyyy HH:MM');
  }
}

export function refreshTokens() {
  if (
    localStorage.getItem('google_auth') &&
    JSON.parse(localStorage.getItem('google_auth')!).refreshToken
  ) {
    return axios
      .post(
        `${STRAPI_BASE_URL}/api/auth/google/refresh`,
        {
          refreshToken: JSON.parse(localStorage.getItem('google_auth')!).refreshToken
        },
        {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.auth).accessToken}` }
        }
      )
      .catch(() => {
        localStorage.removeItem('google_auth');
      });
  }

  return Promise.reject();
}

export function getImagePath(path: string): string {
  const regexp = new RegExp('^http');
  return regexp.test(path) ? path : `${STRAPI_BASE_URL}${path}`;
}
