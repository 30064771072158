import React from 'react';
import './MenuTopic.less';
import { Link } from 'react-router-dom';

interface Props {
  children: any;
  title: string;
  path: string;
}

function MenuTopic(props: Props) {
  return (
    <div className="menu__topic">
      <Link to={props.path} className={props.path === '/' ? 'no-link' : ''}>
        <span className="menu__topic-title">{props.title}</span>
      </Link>
      {props.children}
    </div>
  );
}

export default MenuTopic;
