import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Page from '../Components/Layout/Page';
import StrapiPage from '../Components/Layout/StrapiPage/StrapiPage';
import Home from '../Components/Home/Home';
import { Route } from 'react-router';
import { useIdp } from '../Hooks/useIdp';
import CalendarEvent from '../Components/CalendarEvent/CalendarEvent';

export default function PrivateRoutes() {
  const { isLoggedIn } = useIdp();

  if (isLoggedIn()) {
    return (
      <Page>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/:calendarId/event/:id" component={CalendarEvent} />
          <Route exact path="/:slug" component={StrapiPage} />
          <Route exact path="*/:slug" component={StrapiPage} />
          <Redirect exact path="/" to="/home" />
        </Switch>
      </Page>
    );
  }

  return <Redirect to={{ pathname: '/login' }} />;
}
