import { Content } from '../../../../../Models/Content';
import { useNavigationItems } from '../../../../../Hooks/useNavigationItems';
import { Result } from 'antd';
import React from 'react';
import './MenuListBlock.less';
import MenuListElement from '../MenuListElement/MenuListElement';

interface Props {
  contentItem: Content;
}

export default function MenuListBlock({ contentItem }: Props) {
  const { data, status } = useNavigationItems(contentItem.MenuId ?? -1);

  switch (status) {
    case 'loading':
    case 'success':
    case 'idle':
      return <MenuListElement data={data} />;
    case 'error':
      return (
        <Result
          title={'Er is een fout opgetreden bij het ophalen van de pagina inhoud'}
          status={'error'}
        />
      );
    default:
      return <></>;
  }
}
