import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { STRAPI_BASE_URL } from '../variables';
import { refreshTokens } from '../Utilities/functions';

interface ContextState {
  accessToken?: string;
  setAccessToken: Dispatch<SetStateAction<string | undefined>>;
  refreshToken?: string;
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>;
  expiresIn?: number;
  setExpiresIn: (expires?: number) => void;
  googleLogin: () => void;
  authenticated: boolean;
  setAuthenticated: any;
}

const GoogleContext = React.createContext<ContextState | undefined>(undefined);

export function useGoogle() {
  const context = React.useContext(GoogleContext);
  if (context === undefined) {
    throw new Error('`useGoogle` must be used with an `GoogleProvider`');
  }
  return context;
}

export const GoogleProviderComponent = (props: React.PropsWithChildren<unknown>) => {
  const [accessToken, setAccessToken] = React.useState<string>();
  const [refreshToken, setRefreshToken] = React.useState<string>();
  const [expiresIn, setExpiresIn] = React.useState<number>();
  const [authenticated, setAuthenticated] = React.useState(false);

  useEffect(() => {
    initializeTokenFromStorage();
  }, []);

  useEffect(() => {
    if (!!expiresIn && expiresIn < Date.now()) {
      refreshTokens()
        .then((response: any) => {
          setLocalStateAndStorage(response);
        })
        .catch(() => {
          setAuthenticated(false);
        });
    } else {
      if (expiresIn && accessToken && refreshToken && expiresIn > Date.now()) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    }
  }, [expiresIn]);

  const googleLogin = useGoogleLogin({
    scope:
      'openid email https://www.googleapis.com/auth/userinfo.email profile ' +
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/' +
      'calendar.readonly https://www.googleapis.com/auth/calendar',
    onSuccess: async ({ code }) => {
      const tokensResponse = await axios.post(`${STRAPI_BASE_URL}/api/auth/google`, {
        code
      });

      setLocalStateAndStorage(tokensResponse);
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: 'auth-code'
  });

  const setLocalStateAndStorage = (tokens: any) => {
    const accessToken = tokens.data.access_token;
    const refreshToken = tokens.data.refresh_token;
    const expiresIn = tokens.data.expiry_date;

    localStorage.setItem(
      'google_auth',
      JSON.stringify({
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiresIn: expiresIn
      })
    );
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setExpiresIn(expiresIn);
  };

  const initializeTokenFromStorage = () => {
    if (localStorage.getItem('google_auth')) {
      const { accessToken, expiresIn, refreshToken } = JSON.parse(
        localStorage.getItem('google_auth')!
      );

      setAccessToken(accessToken);
      setExpiresIn(expiresIn);
      setRefreshToken(refreshToken);
    } else {
      setAuthenticated(false);
    }
  };

  const context = {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    expiresIn,
    setExpiresIn,
    googleLogin,
    authenticated,
    setAuthenticated
  };

  return <GoogleContext.Provider value={context}>{props.children}</GoogleContext.Provider>;
};
