import MenuTopic from '../../../../Home/MenuTopic/MenuTopic';
import { Link } from 'react-router-dom';
import React from 'react';
import { NavItem } from '../../../../../Services/NavigationService';

interface Props {
  data: NavItem[] | undefined;
}

export default function MenuListElement({ data }: Props) {
  return (
    <div className="menu-list">
      {data?.map((topic) => {
        return (
          <MenuTopic title={topic.title} path={topic.path} key={topic.title}>
            {topic?.items?.map((item) => {
              const words = item.title.split(' ');
              const first = words[0];
              const rest = words.splice(1).join(' ');

              return (
                <div className="menu__item" key={item.related.slug}>
                  <Link to={item.path}>
                    <span className="menu__item-label">
                      <span>{first}</span> {rest}
                    </span>
                  </Link>
                </div>
              );
            })}
          </MenuTopic>
        );
      })}
    </div>
  );
}
