import React from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export interface Props {
  html: string;
  options?: IOptions;
}

export default function SanitizeHTML({ html, options }: Props) {
  const defaultOptions = {
    allowedAttributes: {
      '*': ['style'],
      'a': ['href'],
      'img': ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
    },
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedIframeHostnames: ['www.youtube.com', 'strapi.intranet.hybrit.dev']
  };

  const sanitize = (dirty: string, options?: IOptions) => ({
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...options })
  });

  return <div dangerouslySetInnerHTML={sanitize(html, options)} />;
}
