import ApiService from './ApiService';
import { Content } from '../Models/Content';

export type Columns = '12' | '8' | '6' | '4' | '3' | '2' | '1';
export type FooterVisibility = 'Global' | 'Always show' | 'Always hide';

export interface StrapiPageResponse {
  data: StrapiPageAttributes[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
      limit?: number;
    };
  };
}

export interface StrapiPageAttributes {
  attributes: StrapiPage;
}

export interface StrapiPage {
  id: number;
  title: string;
  slug: string;
  locale: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  banner: Banner;
  banner_card: Banner;
  Content: Content[];
  navigation: Navigation;
  description: string;
  tags: Tag[];
  localizations: any[];
  show_footer: FooterVisibility;
}

export interface Banner {
  data: {
    attributes: {
      id: number;
      name: string;
      alternativeText: string;
      caption: string;
      width: number;
      height: number;
      formats: Formats;
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl: null;
      provider: string;
      provider_metadata: null;
      createdAt: Date;
      updatedAt: Date;
      publishedAt: Date;
    };
  };
}

export interface Tag {
  id: number;
  attributes: {
    name: string;
    description: string;
    image: Banner;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
  };
}

export interface Formats {
  large: FormatData;
  small: FormatData;
  medium: FormatData;
  thumbnail: FormatData;
}

export interface FormatData {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
}

export interface Navigation {
  id: number;
  title: string;
  type: string;
  path: string;
  externalPath: null;
  uiRouterKey: string;
  menuAttached: boolean;
  order: number;
  parent: null;
  master: number;
  created_at: Date;
  updated_at: Date;
}

class StrapiPageService extends ApiService {
  call = async (slug: string): Promise<StrapiPageResponse> => {
    return this.api.get<StrapiPageResponse>(`/pages?filters[slug][$eq]=${slug}&populate=deep,4`);
  };

  byTag = async (tags: string[], limit: number, start = 0): Promise<StrapiPageResponse> => {
    const pageLimit = limit ? `&pagination[start]=${start}&pagination[limit]=${limit}` : '';
    return this.api.get<StrapiPageResponse>(`/pages?filters[tags][name]
[$eq]=${tags.join('&tags.name=')}&sort[0]=createdAt:DESC${pageLimit}
&populate[banner]=*&populate[banner_card]=*&populate[tags]=*&populate[localizations]=*
&populate[Content][populate]=*`);
  };
}

export const strapiPageService = new StrapiPageService();
